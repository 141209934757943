<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="../../assets/image/icon/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/')"
          >
          <span class="config-title">&nbsp;&nbsp;&nbsp;설정</span>
        </v-row>
        <v-row
          class="setting-area"
          justify="center"
        >
          <v-col
            align-self="center"
            cols="12"
            md="6"
          >
            <template v-if="$store.state.use_menu_location">
              <v-btn
                class="btn mt-9"
                height="130"
                width="100%"
                color="#00BFB4"
                dark
                @click="$router.replace('/location-info')"
              >
                위치 관리
              </v-btn>
              <br>
            </template>
            <template v-if="$store.state.use_menu_task">
              <v-btn
                class="btn mt-9"
                height="130"
                width="100%"
                color="#00BFB4"
                dark
                @click="$router.replace('/task')"
              >
                태스크매니저
              </v-btn>
              <br>
            </template>
            <template v-if="$store.state.use_menu_repeat">
              <v-btn
                class="btn mt-9"
                height="130"
                width="100%"
                color="#00BFB4"
                dark
                @click="$router.replace('/time')"
              >
                태스크 실행 시간설정
              </v-btn>
              <br>
            </template>
            <template v-if="$store.state.use_menu_mapping">
              <v-btn
                class="btn mt-9"
                height="130"
                width="100%"
                color="#00BFB4"
                dark
                @click="$router.replace('/mapping')"
              >
                지도매핑
              </v-btn>
              <br>
            </template>
            <template v-if="$store.state.use_menu_map">
              <v-btn
                class="btn mt-9"
                height="130"
                width="100%"
                color="#00BFB4"
                dark
                @click="$router.replace('/mapinfo')"
              >
                지도변경 및 관리
              </v-btn>
              <br>
            </template>
            <template v-if="$store.state.use_menu_setting">
              <v-btn
                class="btn mt-9"
                height="130"
                width="100%"
                color="#00BFB4"
                dark
                @click="$router.replace('/robot-setting')"
              >
                로봇설정
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Config',
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.container > .header {
  top: 0;
}

.container > .header > .config-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
}

.setting-area {
  height: 70vh;
  overflow: auto;
}

.btn {
  overflow: auto;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
  border-radius: 10px;
}

@media (max-width: 1485px) {
  .header img {
    width: 50px;
    height: 50px;
  }

  .btn {
    font-size: 40px !important;
  }

  .container > .header > .config-title {
    font-size: 40px !important;
  }
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .container > .header > .config-title {
    font-size: 20px !important;
  }

  .btn {
    height: 50px !important;
    font-size: 20px !important;
  }
}
</style>
